const axios = require("axios");

const environment = "stage"; // environment selection
export const sopsList = [
  {
    value: `${environment}_engine_not_started_new`,
    label: "Engine not started new",
    text: "Engine not started new",
  },
  // {
  //   value: `${environment}_engine_not_started`,
  //   label: "Engine not started",
  //   text: "Engine not started",
  // },
  {
    value: `${environment}_ac_cooling_not_effective`,
    label: "AC cooling not effective",
    text: "AC cooling not effective",
  },
  {
    value: `${environment}_engine_not_started_ai`,
    label: "Engine not started AI",
    text: "Engine not started AI",
  },
];
export let API_URL = `https://y4s9rc42m6.execute-api.ap-south-1.amazonaws.com/vda`;

const headers = {
  // "x-origin": 1,
  // "x-version": 1,
  // "x-device-id": "vda.device",
  // "x-platform": 1,
  "Content-Type": "application/json",
};

const requestApi = async (resourcePath, method, params) => {
  const url = API_URL + resourcePath;

  if (resourcePath === "/auths/login") {
    delete headers["x-auth"];
  }
  let response;
  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    response = await axios({ url, method, data: params, headers });
    return response.data;
  }
  response = await axios({ url, method, headers });
  return response.data;
};

export default requestApi;
