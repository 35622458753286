// @ts-nocheck
const removeblankSpaces = (content) => {
  return content
    .toLowerCase()
    .replace(/[\r\n]/gm, "")
    .replaceAll(" ", "");
};
export const multi = [
  "Battery Voltage Check using Battery tester/Multimeter, Check if battery voltage is 12V or more",
  "Check that there is no continuity between terminals “3” and “4”",
  "Measure resistance between terminals “1” and “2” of relay",
  "or continuity exists",
  "less than 1Ω",
  "Specified current (No-load performance test)90 A MAX. at 11 V",
  "Connect battery positive (+) terminal and negative (–) terminal to relay as shown in figure, and check for continuity between terminals “3” and “4”",
  "Relay resistance between terminals “1” and “2” Type A: Approx. 170 Ω at 20 °C (68 °F)Type B: Approx. 100 Ω at 20 °C (68 °F)",
  "Standard Value of Current = 16A",
  "Measure resistance between terminal “1” (MH) – “4” (ML)",
  "Measure resistance between terminal “4” (ML) – “2” (L)",
  "Magnet clutch coil resistance Approx 3.4 Ohm(at 20 ᴼC)",
  "At: 0 ᴼC (32 ᴼF) : 6.7 -6.8 Kohms 25 ᴼC (77 ᴼF) : 2.0-2.1Kohms",
  "Measure resistance between terminal “3” (H) – “1” (MH)",
  "Battery voltage is about 12 V or more",
  //sop1 new
  "Check Battery's Voltage using Multimeter. ",
  "Check relay Switching circuit supply voltage and relay coil circuit voltage with body (10-14V)",
  "Measure resistance between terminals “1” and “2” of relay",
  "Specified current (No-load performance test)90 A MAX. at 11 V",
  " Relay resistance between terminals “1” and “2” Type A: Approx. 170 Ω at 20 °C (68 °F) Type B: Approx. 100 Ω at 20 °C (68 °F)",
  " Take Photo",
  "Take Photo of the multimeter",
  " Infinity",
  " Approx 0V",
  " 12 V",
  " Less than 1 ohm",
  " Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  " less than 1Ω",
  " > Connect 1 wire of multimeter to C01-23 > Connect 2nd wire of multimeter to GRY wire of injector 2-2 connector",
  " > Connect 1 wire of multimeter to C01-10 > Connect 2nd wire of multimeter to Red wire of injector 3-1 connector",
  " > Connect 1 wire of multimeter to C01-32 > Connect 2nd wire of multimeter to BEG wire of injector 3-2 connector",
  " > Connect 1 wire of multimeter to C01-12 > Connect 2nd wire of multimeter to Green wire of injector 4-1 connector",
  " > Connect 1 wire of multimeter to C01-24 > Connect 2nd wire of multimeter to Light green wire of injector 4-2 connector",
  " 12V approx",
  " 0 to 1 V",
  " CKP sensor Resistance is approx. 220 Ω when ferromagnetic material is passed",
  " CKP sensor Resistance is infinity when no ferromagnetic material is passed",
].map((item) => removeblankSpaces(item));
export const battery = [
  "Battery Visual Inspection for Visible damages(Leakage,corrosion checks)",
  "Please upload O-ring image",
  "Battery Visual Inspection for Visible damages(Leakage,corrosion check)",
  "Is O-ring damaged?",
].map((item) => removeblankSpaces(item));
export const dtc = ["Upload photo of  DTC number"].map((item) =>
  removeblankSpaces(item)
);
export const spark = [
  "•Insulator damage",
  "Please upload spark plug image",
].map((item) => removeblankSpaces(item));
export const compressor = [
  "Check Compressor belt for wear, cracks, deformation & contamination",
].map((item) => removeblankSpaces(item));
export const fins = ["Are the Fins clogged?"].map((item) =>
  removeblankSpaces(item)
);
export const filter = ["Is it dirty or clogged?"].map((item) =>
  removeblankSpaces(item)
);
