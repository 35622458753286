// @ts-nocheck

import { Avatar, Box, Grow, Typography } from "@mui/material";
import React from "react";

import { Message, MessageContent } from "../chat-types";

let pinkMessages = [
  "Standard Pressure range of Low Pressure gauge: 230-330 KPA",
  "Standard Pressure range of High Pressure gauge: 1200-1430 KPA",
  "Compressor drive belt tension Standard: 194 – 664 N",
  "Standard Value of Current = 16A",
  "Magnet clutch coil resistance Approx 3.4 Ohm(at 20 ᴼC)",
  "At: 0 ᴼC (32 ᴼF) : 6.7 -6.8 Kohms 25 ᴼC (77 ᴼF) : 2.0-2.1Kohms",
  "Clearance between end of thread and clutch pedal arm“a”: 3.0 – 4.0 mm (0.118 – 0.157 in.)",
  "Tightening torqueCPP No.1 switch lock nut (a): 7.5 N·m (0.76 kgf-m, 5.5 lbf-ft)",
  "Specified torque 1 N·m (1.1 kgf-m, 8.5 lbf-ft)",
  "Specified current (No-load performance test)90 A MAX. at 11 V",
  "Voltage 12 V",
  "Relay resistance between terminals “1” and “2”Type A: Approx. 170 Ω at 20 °C (68 °F)Type B: Approx. 100 Ω at 20 °C (68 °F)",
  "Less than 1 ohm",
  "Infinity",
  "Infinity",
  "approx 0V",
  "approx 0V",
  "Infinity",
  "12 V",
  "Less than 1 ohm",
  "Fuel pressure specification With fuel pump operating and engine stopped: 368.6 – 391.4 kPa (3.8 – 4.0 kgf/cm2, 53.4 – 56.7 psi, 3.686 – 3.914 bar)",
  "Fuel pressure specification With fuel pump operating and engine stopped: 368.6 – 391.4 kPa (3.8 – 4.0 kgf/cm2, 53.4 – 56.7 psi, 3.686 – 3.914 bar)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Infinity",
  "12 V",
  "less than 1Ω",
  "Infinity",
  "Infinity",
  "Approx 0V",
  "Reference injected fuel volume 21.8 – 23.2 cm3/15 sec. (0.046/0.038 – 0.049/0.041 US/Imp pt/15 sec.)",
  "Fuel leakageLess than 1 drop/min",
  "Reference injected fuel volume 21.8 – 23.2 cm3/15 sec. (0.046/0.038 – 0.049/0.041 US/Imp pt/15 sec.)",
  "Fuel leakageLess than 1 drop/min",
  "Reference injected fuel volume 21.8 – 23.2 cm3/15 sec. (0.046/0.038 – 0.049/0.041 US/Imp pt/15 sec.)",
  "Fuel leakageLess than 1 drop/min",
  "Reference injected fuel volume 21.8 – 23.2 cm3/15 sec. (0.046/0.038 – 0.049/0.041 US/Imp pt/15 sec.)",
  "Fuel leakageLess than 1 drop/min",
  "Reference injected fuel volume 21.8 – 23.2 cm3/15 sec. (0.046/0.038 – 0.049/0.041 US/Imp pt/15 sec.)",
  "Fuel leakageLess than 1 drop/min",
  "Reference injected fuel volume 21.8 – 23.2 cm3/15 sec. (0.046/0.038 – 0.049/0.041 US/Imp pt/15 sec.)",
  "Fuel leakageLess than 1 drop/min",
  "Reference injected fuel volume 21.8 – 23.2 cm3/15 sec. (0.046/0.038 – 0.049/0.041 US/Imp pt/15 sec.)",
  "Fuel leakageLess than 1 drop/min",
  "Reference injected fuel volume 21.8 – 23.2 cm3/15 sec. (0.046/0.038 – 0.049/0.041 US/Imp pt/15 sec.)",
  "less than 1 ohm",
  "infinity",
  "infinity",
  "infinity",
  "infinity",
  "0 to 1 V",
  "CKP sensor resistanceResistance alternates between 220 Ω or smaller and infinity.",
  "Approx. 0 VCMP sensor is facing to projection of signal rotor.",
  "Approx. 5 VCMP sensor is not facing to projection of signal rotor.",
  "Specified current (No-load performance test)90 A MAX. at 11 V",
  "Relay resistance between terminals “1” and “2” Type A: Approx. 170 Ω at 20 °C (68 °F) Type B: Approx. 100 Ω at 20 °C (68 °F)",
  "Voltage 12 V",
  "Less than 1 ohm",
  "Infinity",
  "Infinity",
  "approx 0V",
  "approx 0V",
  "Infinity",
  "12 V",
  "Less than 1 ohm",
  "Fuel pressure specification With fuel pump operating and engine stopped: 368.6 – 391.4 kPa (3.8 – 4.0 kgf/cm2, 53.4 – 56.7 psi, 3.686 – 3.914 bar)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Reference resistance of fuel injector11.6 – 12.4 Ω at 20 °C (68 °F)",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "12 V",
  "12 V",
  "12 V",
  "12 V",
  "12 V",
  "12 V",
  "12 V",
  "less than 1Ω",
  "less than 1Ω",
  "less than 1Ω",
  "less than 1Ω",
  "less than 1Ω",
  "less than 1Ω",
  "less than 1Ω",
  "less than 1Ω",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Infinity",
  "Approx 0V",
  "Approx 0V",
  "Approx 0V",
  "1mm to 1.1 mm",
  "1mm to 1.1 mm",
  "1mm to 1.1 mm",
  "1mm to 1.1 mm",
  "12V approx",
  "12V approx",
  "12V approx",
  "12V approx",
  "less than 1 ohm",
  "less than 1 ohm",
  "less than 1 ohm",
  "less than 1 ohm",
  "infinity",
  "infinity",
  "infinity",
  "infinity",
  "infinity",
  "infinity",
  "infinity",
  "infinity",
  "0 to 1 V",
  "0 to 1 V",
  "0 to 1 V",
  "0 to 1 V",
  "CKP sensor Resistance is approx. 220 Ω when ferromagnetic material is passed",
  "CKP sensor Resistance is infinity when no ferromagnetic material is passed",
  "Fuel pressure specification With fuel pump operating and engine stopped: 3.8 – 4.0 kgf/cm2",
  "Fuel pressure specification With fuel pump operating and engine stopped: 368.6 – 391.4 kPa",
];

let headings = [
  "Battery Visual Inspection for Visible damages(Leakage,corrosion check)",
  "Check Battery's Voltage using Multimeter",
  "Check Battery's Health using the Battery Tester",
  "Take a photo of the fuel indicator to verify the amount of fuel available in the fuel tank",
  "Immobilizer Control System Check DTC check",
  "Check Poor ground cable connection",
  "Check Starter Motor Fuse",
  "Check Starter Motor Fuse continuity using multimeter",
  "Check Starter Motor Relay",
  "Check Starter Motor Control Relay Supply",
  "Check magnetic switch lead wire connector connection",
  "Check magnetic switch",
  "Perform pull-in test",
  "Perform HOLD-in test",
  "Perform plunger and pinion return test",
  "Perform no-load performance test",
  "Check ignition switch",
  "Fuel Pump On-Vehicle Inspection",
  "Check fuel injector circuit",
  "Fuel injector resistance check",
  "Fuel injector insulation resistance check",
  "Fuel injector power supply circuit check",
  "Fuel injector drive circuit check",
  "Ignition System Check",
  "Ignition coil assembly drive circuit check",
  "CKP sensor and CMP sensor check",
];
const changeBgToPink = (message) => {
  const pinkArray = pinkMessages.map((element) => {
    return element
      .toLowerCase()
      .replace(/[\r\n]/gm, "")
      .replaceAll(" ", "");
  });

  if (
    typeof message === "string" &&
    pinkArray.includes(
      message
        .toLowerCase()
        .replace(/[\r\n]/gm, "")
        .replaceAll(" ", "")
    )
  ) {
    return "#e75480";
  }
  return "background.paper";
};
const changeColorToPink = (message) => {
  const pinkArray = pinkMessages.map((element) => {
    return element
      .toLowerCase()
      .replace(/[\r\n]/gm, "")
      .replaceAll(" ", "");
  });
  if (
    typeof message === "string" &&
    pinkArray.includes(
      message
        .toLowerCase()
        .replace(/[\r\n]/gm, "")
        .replaceAll(" ", "")
    )
  ) {
    return "white";
  }
  return "text.primary";
};
const changeTextToBold = (message) => {
  const headingArray = headings.map((element) => {
    return element
      .toLowerCase()
      .replace(/[\r\n]/gm, "")
      .replaceAll(" ", "");
  });
  if (
    typeof message === "string" &&
    headingArray.includes(
      message
        .toLowerCase()
        .replace(/[\r\n]/gm, "")
        .replaceAll(" ", "")
    )
  ) {
    return true;
  }
  return false;
};

export function MuiMessage({
  id,
  message,
  showDate,
  showTime,
}: {
  id: string;
  message: Message<MessageContent>;
  showDate: boolean;
  showTime: boolean;
}): React.ReactElement {
  if (message.deletedAt) {
    return <div id={id} />;
  }

  const dispDate = message.updatedAt ? message.updatedAt : message.createdAt;

  const ChatAvator = (
    <Box
      minWidth={0}
      flexShrink={0}
      ml={message.self ? 1 : 0}
      mr={message.self ? 0 : 1}
    >
      <Avatar alt={message.username} src={message.avatar} />
    </Box>
  );

  const ChatUsername = (
    <Box maxWidth="100%" mx={1}>
      <Typography variant="body2" align={message.self ? "right" : "left"}>
        {message.username}
      </Typography>
    </Box>
  );

  const ChatDate = (
    <Box maxWidth="100%" mx={1}>
      <Typography
        variant="body2"
        align={message.self ? "right" : "left"}
        color="textSecondary"
      >
        {dispDate?.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Typography>
    </Box>
  );

  return (
    <Grow in>
      <Box maxWidth="100%" display="flex" flexDirection="column">
        {showDate && (
          <Typography align="center">
            {dispDate?.toLocaleDateString()}
          </Typography>
        )}
        <Box
          id={id}
          maxWidth="100%"
          my={1}
          pl={message.self ? "20%" : 0}
          pr={message.self ? 0 : "20%"}
          display="flex"
          justifyContent={message.self ? "flex-end" : "flex-start"}
          style={{ overflowWrap: "break-word" }}
        >
          {message.avatar && !message.self && ChatAvator}
          <Box minWidth={0} display="flex" flexDirection="column">
            {message.username && ChatUsername}
            <Box
              maxWidth="100%"
              py={1}
              px={2}
              bgcolor={
                message.self ? "primary.main" : changeBgToPink(message.content)
              }
              color={
                message.self
                  ? "primary.contrastText"
                  : changeColorToPink(message.content)
              }
              borderRadius={4}
              boxShadow={2}
            >
              {message.type === "text" && (
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: "pre-wrap",
                    fontWeight: changeTextToBold(message.content)
                      ? "bolder"
                      : "unset",
                  }}
                >
                  {message.content}
                </Typography>
              )}
              {message.type === "jsx" && <div>{message.content}</div>}
            </Box>
            {showTime && ChatDate}
          </Box>
          {message.avatar && message.self && ChatAvator}
        </Box>
      </Box>
    </Grow>
  );
}
