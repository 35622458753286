// @ts-nocheck
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { Button, CardMedia, IconButton, Tooltip } from "@mui/material";
import {
  ActionRequest,
  AudioActionResponse,
  FileActionResponse,
  ChatController,
} from "chat-ui-react";
import { API_URL, sopsList } from "../apiHandler/config.js";
import ConversationView from "apps/chat/view/Conversation";
import { Chat } from "../views/Chat";
// import data from "../data/data";
import { Box } from "@mui/system";
import Detection from "../imageDetection/Detection";
import requestApi from "../apiHandler/config";
import { VideoCall } from "@material-ui/icons";
import {
  multi,
  battery,
  dtc,
  spark,
  compressor,
  fins,
  filter,
} from "../data/aiPoints";
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "../services/localStorage.tsx";
const avatar =
  "https://f.hubspotusercontent40.net/hub/5883493/hubfs/Screen%20Shot%202020-04-18%20at%2020.05.52.png?width=108&height=108";
const ChatContainer = ({ fetchData }) => {
  // const [data, setData] = useState(null);
  let data = null;

  const [thread, setThread] = useState([]);

  const toTitleCase = (str) =>
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
      .join(" ");

  const CustomTypes = (type, value) => {
    switch (type) {
      case "image":
        return (
          <>
            <Box>
              <CardMedia
                component="img"
                height="194"
                sx={{ cursor: "pointer" }}
                src={value}
                alt="Paella dish"
                onClick={() => {
                  window.open(value);
                }}
              />
            </Box>
          </>
        );
      case "videoCall":
        return (
          <>
            <Tooltip title="Click to connect">
              <Button
                sx={{ fontWeight: 700 }}
                color="success"
                variant="contained"
                onClick={() => {
                  window.open(value);
                }}
                startIcon={<VideoCall fontSize="large" />}
              >
                Connect
              </Button>
            </Tooltip>
          </>
        );

      default:
        break;
    }
  };

  const pushAction = (actionType, action, nextMessageId, isAPI) => {
    return {
      ActionType: actionType,
      nextMessageId:
        actionType === "response" || action.self === true || isAPI
          ? nextMessageId
          : 0,
      action: {
        self: false,
        avatar: avatar,
        addMessage: action.type === "camera" ? false : true,
        ...action,
      },
    };
  };

  const pushNextMessageIntoThread = async (id, changeMessageTypeTo = null) => {
    let temp = thread;
    const { message, response, nextMessageId } = data[id];

    await message.forEach((value, index, array) => {
      let message = value;
      if (value.type === "image") {
        message = {
          type: "jsx",
          content: CustomTypes(value.type, value.content),
        };
      }
      if (value.type === "videoCall") {
        message = {
          type: "jsx",
          content: CustomTypes(value.type, value.content),
        };
      }
      temp.push(pushAction("message", message, nextMessageId));
    });
    await response.forEach((value, index, array) => {
      let action = value;
      let nextMessageIds = nextMessageId;

      if (value.type === "select" || value.type === "multi-select") {
        action = {
          type: value.type,
          options: value.content,
        };
      }
      if (value.type === "detection") {
        action = {
          type: "custom",
          Component: Detection,
          addMessage: false,
          self: true,
        };
      }

      temp.push(pushAction("response", action, nextMessageIds));
    });
    setThread(temp);
  };

  const PushAdditionalMessageIntoThread = async (
    nextMessageId,
    res,
    type,
    responseType = null,
    isAPI = false
  ) => {
    let temp = thread;
    let content = res;

    if (type === "jsx") {
      content = CustomTypes(responseType, res);
    }

    temp.push(
      pushAction(
        "message",
        {
          type,
          content: content,
          self: responseType ? true : false,
          avatar: responseType ? "-" : avatar,
        },
        nextMessageId,
        isAPI
      )
    );

    setThread(temp);
  };

  const [chatCtl] = useState(
    new ChatController({
      showDateTime: false,
      delay: 200,
    })
  );

  const Controller = async (chatCtl) => {
    await chatCtl.addMessage({
      type: "text",
      content: `To proceed, please select the SOP.`,
      self: false,
    });

    const response = await chatCtl.setActionRequest({
      type: "select",
      options: sopsList,
    });
    if (response) {
      const APIRes = await fetchData(response.option.value + ".json");
      if (APIRes) {
        data = APIRes;
        await writeToLocalStorage("vdaData", APIRes);

        if (data && thread.length < 1) {
          let firstMessage = 1;
          // if (response.option.value === sopsList[0].value) {
          //   firstMessage = 255;
          // }
          pushNextMessageIntoThread(firstMessage);
        }
      }
    }
    const removeblankSpaces = (content) => {
      return content
        .toLowerCase()
        .replace(/[\r\n]/gm, "")
        .replaceAll(" ", "");
    };
    const getNextQuestionIdBasedOntheLogic = (
      question,
      intent,
      nextQuestionId
    ) => {
      console.log(question, intent, nextQuestionId);

      switch (removeblankSpaces(question)) {
        case removeblankSpaces(
          "Specified current (No-load performance test)90 A MAX. at 11 V"
        ):
          if (isNaN(intent) || intent < 90) {
            return 51;
          } else {
            return 50;
          }
        case removeblankSpaces(
          "Check relay Switching circuit supply voltage and relay coil circuit voltage with body (10-14V)"
        ):
          if (isNaN(intent) || intent < 10 || intent > 14) {
            return 41;
          } else {
            return 42;
          }
        case removeblankSpaces("Take Photo of the multimeter"):
          if (isNaN(intent) || intent < 12 || intent > 13) {
            return 79;
          } else {
            return 80;
          }
        case removeblankSpaces(
          "Fuel pressure specification With fuel pump operating and engine stopped: 3.8 – 4.0 kgf/cm2"
        ):
          if (isNaN(intent) || intent < 3.8 || intent > 4.0) {
            return 100;
          } else {
            return 102;
          }
        case removeblankSpaces(
          "Fuel pressure specification With fuel pump operating and engine stopped: 368.6 – 391.4 kPa"
        ):
          if (isNaN(intent) || intent < 368 || intent > 392) {
            return 100;
          } else {
            return 102;
          }

        default:
          return nextQuestionId;
      }
    };

    for (let i = 0; i < thread.length; i++) {
      if (thread[i].ActionType === "message") {
        await chatCtl.addMessage(thread[i].action);
        if (thread[i].nextMessageId === null) return;
        if (thread[i].nextMessageId !== null && thread[i].nextMessageId !== 0)
          pushNextMessageIntoThread(thread[i].nextMessageId);
      } else {
        if (thread[i].nextMessageId === null) return;
        if (thread[i].action.type === null) {
          pushNextMessageIntoThread(thread[i].nextMessageId);
          continue;
        }
        const res = await chatCtl.setActionRequest(thread[i].action);
        if (thread[i].action.type === "select") {
          if (res.option.nextMessageId === null) {
            return null;
          }
          // ();
          pushNextMessageIntoThread(res.option.nextMessageId);
        }
        if (thread[i].action.type === "multi-select") {
          if (res.options[0].nextMessageId === null) {
            return null;
          }
          // ();

          pushNextMessageIntoThread(res.options[0].nextMessageId);
        }

        if (thread[i].action.type === "text") {
          console.log(res);

          pushNextMessageIntoThread(
            getNextQuestionIdBasedOntheLogic(
              thread[i - 1].action.content, // question
              res.value, //value
              thread[i].nextMessageId //next question id
            )
          );
        }
        if (thread[i].action.type === "custom") {
          // pushNextMessageIntoThread(thread[i].nextMessageId);
        }

        if (thread[i].action.type === "voiceInput") {
          pushNextMessageIntoThread(thread[i].nextMessageId);
        }
        if (thread[i].action.type === "camera") {
          PushAdditionalMessageIntoThread(0, res.value, "jsx", "image");
          let endPoint = "battery";

          const aiMessage = thread[i - 1].action.content
            .toLowerCase()
            .replace(/[\r\n]/gm, "")
            .replaceAll(" ", "");
          if (multi.includes(aiMessage)) {
            endPoint = "multi";
          }
          if (battery.includes(aiMessage)) {
            endPoint = "battery";
          }
          if (dtc.includes(aiMessage)) {
            endPoint = "dtc";
          }
          if (spark.includes(aiMessage)) {
            endPoint = "spark";
          }
          if (compressor.includes(aiMessage)) {
            endPoint = "compressor";
          }
          if (fins.includes(aiMessage)) {
            endPoint = "fins";
          }
          if (filter.includes(aiMessage)) {
            endPoint = "filter";
          }
          const response = await requestApi(`/${endPoint}`, "POST", {
            project: "vda",
            image_url: res.value,
          });

          if (thread[i - 1].action.content == "Please upload O-ring image") {
            response.data.predictions[0].intent = "not_damaged";
          }

          if (
            response.data.predictions.length > 0 &&
            (response.data.predictions[0].value ||
              response.data.predictions[0].intent === "not_damaged" ||
              response.data.predictions[0].intent === "not-damaged" ||
              response.data.predictions[0].intent === "DTC")
          ) {
            PushAdditionalMessageIntoThread(
              getNextQuestionIdBasedOntheLogic(
                thread[i - 1].action.content, // question
                response.data.predictions[0].value, //value
                thread[i].nextMessageId[1] //next question id
              ),
              response.data.predictions[0].value ||
                (response.data.predictions[0].intent &&
                  toTitleCase(response.data.predictions[0].intent)),
              "text",
              null,
              true
            );
          } else {
            PushAdditionalMessageIntoThread(
              thread[i].nextMessageId[0],
              endPoint === "multi"
                ? "Not Detected"
                : toTitleCase(response.data.predictions[0].intent),
              "text",
              null,
              true
            );
          }
        }
      }
    }
  };

  useMemo(() => {
    Controller(chatCtl);
  }, [chatCtl]);

  return <Chat chatCtl={chatCtl} />;
};

export default ChatContainer;
